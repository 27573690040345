import * as RD from "src/types/remoteData";
import { Env, useEnv } from "src/services/env";
import useAccessToken from "src/hooks/useAccessToken";
import { Status } from "src/types/authData";
import { useEffect, useState } from "react";
import { FormikForm } from "../components/ConfigForm";
import { AllOrgConfigs, AllOrgConfigsSchema } from "../types";
import * as OrgConfig from "@avela/organization-config-sdk";
import _ from "lodash";

type Props = {
  orgPath: RD.RemoteData<unknown, string>;
};
export const useOrgService = (
  props: Props
): {
  allOrgConfigs: RD.RemoteData<Error, AllOrgConfigs>;
  updateOrgConfigs: (orgPath: string, configs: FormikForm) => Promise<void>;
} => {
  const { orgPath } = props;
  const env = useEnv();
  const token = useAccessToken();
  const [remoteData, setRemoteData] = useState<
    RD.RemoteData<Error, AllOrgConfigs>
  >(RD.loading());

  useEffect(() => {
    const doFetch = async () => {
      try {
        if (token.status !== Status.OK || !orgPath.hasData()) {
          return;
        }

        if (remoteData.hasData()) {
          return;
        }

        const allConfigs = await fetchAllOrgConfigs(
          env,
          orgPath.data,
          token.data
        );
        setRemoteData(RD.success(allConfigs));
      } catch (err) {
        setRemoteData(RD.failure(err as Error));
      }
    };

    doFetch();
  }, [env, orgPath, token, remoteData]);

  const updateOrgConfigs = async (orgPath: string, form: FormikForm) => {
    if (token.status !== Status.OK) {
      throw new Error("Missing access token");
    }

    const payload = getPayload(form, remoteData.toNullable());
    if (_.isEmpty(payload)) {
      return;
    }

    const url = `${env.REACT_APP_ORGANIZATION_SERVICE_URL}/config/v1/${orgPath}`;
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.data}`,
      },
    });

    if (response.status !== 200) {
      console.error(response.statusText);
      throw new Error("Failed to update config");
    }
  };

  return {
    updateOrgConfigs,
    allOrgConfigs: remoteData,
  };
};

function getPayload(
  configs: FormikForm,
  existingConfigs: AllOrgConfigs | null
) {
  const entries = Object.entries(configs).flatMap(
    ([key, config]): [string, typeof config | null][] => {
      if (existingConfigs) {
        if (!hasChange(key as OrgConfig.Type, existingConfigs, configs)) {
          return [];
        }
      }

      if (config.disabled) {
        return [[key, null]];
      }

      return [[key, config]];
    }
  );

  const payload = Object.fromEntries(entries);
  console.log({ payload });
  return payload;
}

function hasChange(
  type: OrgConfig.Type,
  existingConfigs: AllOrgConfigs,
  newConfigs: FormikForm
): boolean {
  const existingConfig: any = existingConfigs[type];
  const newConfig: any = newConfigs[type];
  if (
    (existingConfig.disabled && newConfig.disabled) ||
    _.isEqual(existingConfig, newConfig)
  ) {
    // no change to this config, skip from payload
    return false;
  }

  return true;
}

async function fetchAllOrgConfigs(
  env: Env,
  organizationPath: string,
  token: string
): Promise<AllOrgConfigs> {
  try {
    const url = `${env.REACT_APP_ORGANIZATION_SERVICE_URL}/config/v1/${organizationPath}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const json = await response.json();
    const parsed = AllOrgConfigsSchema.parse(json);
    return parsed;
  } catch (err) {
    console.error(err);
  }

  return {
    Login: { disabled: true },
    TranslationOptions: { disabled: true },
    Match: { disabled: true },
    ScheduleExport: { disabled: true },
    AccountLookup: { disabled: true },
  };
}
