import { Button } from "@chakra-ui/button";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { Flex, Heading, Spacer, VStack } from "@chakra-ui/layout";
import { Select } from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import React, { useMemo } from "react";
import { NavLink } from "react-router-dom";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import * as ConfigForm from "./components/ConfigForm";
import { AllOrgConfigs } from "./types";
import { validateWithZod } from "src/services/formValidations";
import { z } from "zod";
import { InputControl } from "formik-chakra-ui";

export const FormikSchema = z.object({
  name: z.string().min(1, "This field is required"),
  path: z.string().min(1, "This field is required"),
  timezoneName: z.string().min(1, "This field is required"),
  organizationConfigs: ConfigForm.ForkmikSchema,
});

export type FormikForm = z.infer<typeof FormikSchema>;

interface OrganizationFormProps {
  organization?: GQL.GetOrganization_organization_by_pk;
  configs?: AllOrgConfigs;
  onSubmit: (values: FormikForm) => void;
  submitting: boolean;
}

export const OrganizationForm = (props: OrganizationFormProps) => {
  const isNew = !props.organization;
  const timezoneNames = React.useMemo(() => {
    return (Intl as any).supportedValuesOf("timeZone");
  }, []);

  const initialValues: FormikForm = useMemo(() => {
    return {
      organizationConfigs: ConfigForm.getInitialValues(props.configs),
      name: props?.organization?.name || "",
      path: props?.organization?.path || "",
      timezoneName: props?.organization?.timezone_name || "+00:00",
    };
  }, [
    props.configs,
    props?.organization?.name,
    props?.organization?.path,
    props?.organization?.timezone_name,
  ]);

  function editPolicyUrl(
    organization: GQL.GetOrganization_organization_by_pk
  ): string {
    return Url.Admin.Organizations.editPolicy(organization.id);
  }

  return (
    <Formik<FormikForm>
      initialValues={initialValues}
      validate={validateWithZod(FormikSchema)}
      onSubmit={props.onSubmit}
    >
      {(form) => (
        <Form>
          <VStack align="left" spacing={8}>
            <Heading as="h1" size="xl">
              {isNew
                ? "Create Organization"
                : `Edit ${props?.organization?.name || "Organization"}`}
            </Heading>
            <Heading as="h2" size="lg">
              Basic information
            </Heading>
            <Flex direction="column">
              <InputControl name="name" label="Organization Name">
                <Input size="sm" placeholder="Organization Name" />
              </InputControl>
            </Flex>
            <Flex direction="column">
              <InputControl name="path" label="Path">
                <Input size="sm" placeholder="Path" />
              </InputControl>
            </Flex>
            <Flex direction="column">
              <FormControl>
                <FormLabel htmlFor="timezoneName">
                  Timezone offset from UTC
                </FormLabel>
                <Field as={Select} id="timezoneName" name="timezoneName">
                  {timezoneNames.map((value: string) => (
                    <option value={value} key={value}>
                      {value}
                    </option>
                  ))}
                </Field>
              </FormControl>
            </Flex>

            <hr />

            {!!props.organization && (
              <>
                <Heading as="h2" size="lg">
                  Access & policies
                </Heading>
                <p>Configure access policies for the organization.</p>
                <Flex align="center">
                  <Button
                    as={NavLink}
                    colorScheme="gray"
                    to={editPolicyUrl(props.organization)}
                    aria-label={`Edit access policies`}
                    variant="outline"
                  >
                    Edit access policies
                  </Button>
                  <Spacer />
                </Flex>
                <hr />
              </>
            )}

            <Heading as="h2" size="lg">
              General settings
            </Heading>

            <ConfigForm.ConfigForm />

            <Flex align="center">
              <Spacer />
              <Button
                as={NavLink}
                to={Url.Admin.Organizations.index()}
                variant="link"
              >
                Cancel
              </Button>
              <Button type="submit" marginLeft={4} isLoading={props.submitting}>
                {isNew ? "Create" : "Update"}
              </Button>
            </Flex>
          </VStack>
        </Form>
      )}
    </Formik>
  );
};
