import * as GQL from "src/types/graphql";
import * as Defaults from "./defaults";

type TemplateType = GQL.message_template_type_enum;
type Template = {
  type: TemplateType;
  emailSubject: string;
  emailHtml: string;
  emailText: string;
  sms: string;
};

export const defaultTemplate: Record<TemplateType, Template> = {
  FormStarted: Defaults.FORM_STARTED,
  FormSubmitted: Defaults.FORM_SUBMITTED,
  FormCancelled: Defaults.FORM_CANCELLED,
  FormAdminCancelled: Defaults.FORM_ADMIN_CANCELLED,
  FormDeleted: Defaults.FORM_DELETED,
  OfferExtended: Defaults.OFFER_EXTENDED,
  OfferAccepted: Defaults.OFFER_ACCEPTED,
  OfferDeclined: Defaults.OFFER_DECLINED,
  OfferRevoked: Defaults.OFFER_REVOKED,
  Waitlisted: Defaults.WAITLISTED,
  WaitlistWithdrawn: Defaults.WAITLIST_WITHDRAWN,
  WaitlistRemoved: Defaults.WAITLIST_REMOVED
};
