import * as Form from "src/services/form";
import * as GQL from "src/types/graphql";
import * as RD from "src/types/remoteData";
import { RemoteData } from "src/types/remoteData";
import { QuestionsMap } from "../formTemplate/question";
import { formatIsoDate, formatIsoDateToIso8601 } from "../format";
import { isNotNull } from "../predicates";

type GqlQuestionsByFormTemplate =
  GQL.ExportQuestionsAndVerifications_question_by_form_template;
type GqlVerification = GQL.ExportQuestionsAndVerifications_form_verification;

type Person = {
  id: string | null | undefined;
  reference_id: string | null | undefined;
  first_name: string | null | undefined;
  middle_name: string | null | undefined;
  last_name: string | null | undefined;
  birth_date?: string | null | undefined;
  street_address: string | null | undefined;
  street_address_line_2: string | null | undefined;
  city: string | null | undefined;
  state: string | null | undefined;
  zip_code: string | null | undefined;
  phone_number: string | null | undefined;
  email_address: string | null | undefined;
  preferred_language: string | null | undefined;
};
export const formatPerson = (
  person: Person | null,
  prefix: "Student" | "Guardian" | `Guardian ${number}`
) => {
  const result = {
    [`${prefix} ID`]: person?.id,
    [`${prefix} Reference ID`]: person?.reference_id,
    [`${prefix} First Name`]: person?.first_name,
    [`${prefix} Middle Name`]: person?.middle_name,
    [`${prefix} Last Name`]: person?.last_name,
    [`${prefix} Address`]: [
      person?.street_address,
      person?.street_address_line_2,
    ]
      .filter((str) => !!str)
      .join(", "),
    [`${prefix} City`]: person?.city,
    [`${prefix} State`]: person?.state,
    [`${prefix} Zip`]: person?.zip_code,
  };
  if (prefix === "Student")
    result[`${prefix} Birth Date`] =
      person?.birth_date && formatIsoDate(person.birth_date, "MM/dd/yyyy");
  if (prefix.startsWith("Guardian")) {
    result[`${prefix} Email`] = person?.email_address;
    result[`${prefix} Phone`] = person?.phone_number;
    result[`${prefix} Preferred Language`] = formatLanguage(
      person?.preferred_language
    );
  }
  return result;
};

export type ExportInput = {
  records: GQL.ExportForms;
  organization: GQL.Organization;
  questionsMap: QuestionsMap;
  questionIdsInOrder: uuid[];
  formVerifications: GQL.ExportQuestionsAndVerifications_form_verification[];
  disclaimerSectionTitle: string | null;
  tagGroups: GQL.GetTagGroupsByEnrollmentPeriod_tag_group[];
  options: {
    skipRank: boolean;
    includeAttendingSchool: boolean;
  };
};

export function flattenForm({
  records,
  organization: organizationUnwrapped,
  questionsMap,
  questionIdsInOrder,
  formVerifications,
  disclaimerSectionTitle,
  tagGroups,
  options: { skipRank, includeAttendingSchool },
}: ExportInput) {
  // We need to rewrap organization in RemoteData since that is the type that many downstream functions take.
  const organization = RD.success(organizationUnwrapped);
  const groupedByIds = groupFormDetailsById(records);
  return groupedByIds.map((group) => {
    return {
      "App ID": group.formId,
      ...flattenFormDetail(group, organization),
      ...flattenFormSubStatus(group, organization),
      ...flattenWaitlist(group),
      ...flattenTiebreaker(group),
      ...flattenSchoolForm(group, skipRank, organization),
      ...flattenTags(group, tagGroups),
      ...flattenGrade(group),
      ...formatPerson(group.applicant, "Student"),
      ...formatPerson(group.guardian, "Guardian"),
      ...formatVerifications(group, formVerifications),
      ...(includeAttendingSchool ? flattenAttendingSchools(group) : {}),
      ...flattenAnswers(group, questionsMap, questionIdsInOrder),
      ...flattenDisclaimer(group, disclaimerSectionTitle),
    };
  });
}

function flattenGrade(group: GroupedById): { "APL Grade": string | null } {
  const relatedGrade = Form.getGrade({
    previous_offer: group.form.previous_offer,
    previous_waitlist: group.form.previous_waitlist,
    previous_form: group.form.previous_form,
    grades_answers: group.form.grades_answers,
  });

  return { "APL Grade": relatedGrade ?? null };
}

function flattenAnswers(
  group: GroupedById,
  questionsMap: QuestionsMap,
  questionIdsInOrder: uuid[]
) {
  return questionIdsInOrder.reduce((current, questionId) => {
    const question: GqlQuestionsByFormTemplate | undefined = questionId
      ? questionsMap.get(questionId)
      : undefined;
    if (!question) {
      throw new Error(`Question not found for ID: ${questionId}`);
    }
    const text = question.question?.question.replace(/\r?\n|\r/g, " ");

    const answer = group.answers[questionId];

    if (question.question?.type === GQL.question_type_enum.Address) {
      return {
        ...current,
        ...formatAddressAnswer(question, answer?.formAddress ?? null),
      };
    }

    if (question.question?.type === GQL.question_type_enum.CustomQuestion) {
      return {
        ...current,
        ...formatCustomQuestionAnswer(
          questionId,
          question,
          questionsMap,
          group
        ),
      };
    }

    // Skip if answer is for a custom question field, as it's accounted for in formatCustomQuestionAnswer()
    if (
      question.question?.custom_question_relationship
        ?.custom_question_type_field_id
    ) {
      return current;
    }

    return {
      ...current,
      [`App Question "${text}"`]: formatAnswer(
        question,
        answer?.formAnswer ?? null,
        answer?.gradeAnswer ?? null
      ),
    };
  }, {});
}

const formatAddressAnswer = (
  question: GqlQuestionsByFormTemplate,
  address: null | GQL.ExportForms_form_form_addresses
) => {
  const text = question.question?.question.replace(/\r?\n|\r/g, " ");
  return {
    [`"${text}" Street Address`]: address?.street_address,
    [`"${text}" Street Address Line 2`]: address?.street_address_line_2,
    [`"${text}" City`]: address?.city,
    [`"${text}" State`]: address?.state,
    [`"${text}" Zip`]: address?.zip_code,
  };
};

const formatCustomQuestionAnswer = (
  questionId: string,
  question: GqlQuestionsByFormTemplate,
  questionsMap: QuestionsMap,
  group: GroupedById
) => {
  if (!question.question) return {};

  const rootQuestionText = question.question.question.replace(/\r?\n|\r/g, " ");
  const customQuestionClonedQuestionIds =
    question.question.custom_question?.custom_question_relationships.map(
      (relationship) => relationship.cloned_question_id
    ) ?? [];

  return [questionId, ...customQuestionClonedQuestionIds].reduce(
    (current, targetQuestionId) => {
      const answer = group.answers[targetQuestionId];

      if (questionId === targetQuestionId)
        return {
          ...current,
          [`"${rootQuestionText}" Reference ID`]: answer?.externalId,
        };

      const fieldQuestion = questionsMap.get(targetQuestionId);
      return fieldQuestion
        ? {
            ...current,
            [`"${rootQuestionText}" ${fieldQuestion.question?.question}`]:
              formatAnswer(
                fieldQuestion,
                answer?.formAnswer ?? null,
                answer?.gradeAnswer ?? null
              ),
          }
        : current;
    },
    {}
  );
};

const formatAnswer = (
  question: GqlQuestionsByFormTemplate,
  answer: null | GQL.ExportForms_form_form_answers,
  grade_answer: null | GQL.ExportForms_form_grades_answers
): string | null => {
  if (!question.question?.type) return null;
  switch (question.question.type) {
    case GQL.question_type_enum.FreeText:
    case GQL.question_type_enum.Email:
    case GQL.question_type_enum.PhoneNumber:
      return answer
        ? Array.isArray(answer.free_text_answer)
          ? answer.free_text_answer.join(", ")
          : answer.free_text_answer ?? null
        : null;
    case GQL.question_type_enum.Grades:
      return grade_answer?.grade_config?.label ?? null;
    case GQL.question_type_enum.SingleSelect:
    case GQL.question_type_enum.MultiSelect:
      const optionsMap = new Map(
        (question.question.form_question?.form_question_options ?? []).map(
          (o) => [o.id, o.label]
        )
      );

      return (
        answer?.form_answer_options
          .map((option) => optionsMap.get(option.form_question_option_id))
          .filter(isNotNull)
          .join(", ") ?? null
      );
    case GQL.question_type_enum.FileUpload:
      return null; // TODO: file upload?
    case GQL.question_type_enum.Address:
      return null; // This is handled by formatAddressAnswer().
    case GQL.question_type_enum.CustomQuestion: // This is handled by formatCustomQuestionAnswer().
      return null;
    default:
      const _exhaustiveCheck: never = question.question.type;
      return null && _exhaustiveCheck; // Return null just in case.
  }
};

type SchoolFormRow = {
  Rank?: number | null;
  "School ID": uuid | null;
  School: string | null;
  "School Submitted Date": string | null;
};
function flattenSchoolForm(
  group: GroupedById,
  skipRank: boolean,
  organization: RemoteData<unknown, GQL.Organization>
): SchoolFormRow {
  if (!group.formSchoolRank) {
    // check if there's previous offer/waitlist
    const relatedSchool = group.form
      ? Form.Related.getRelatedSchool(group.form)
      : undefined;

    if (relatedSchool) {
      return {
        Rank: null,
        "School ID": relatedSchool.id ?? null,
        School: relatedSchool.name,
        "School Submitted Date": null,
      };
    }
    return {
      Rank: null,
      "School ID": null,
      School: null,
      "School Submitted Date": null,
    };
  }

  return {
    ...(skipRank ? {} : { Rank: group.formSchoolRank.rank + 1 }),
    "School ID": group.formSchoolRank.school.id,
    School: group.formSchoolRank.school.name,
    "School Submitted Date": formatIsoDateToIso8601(
      group.formSchoolRank.form_school_offer_status_history?.submitted_at ??
        null,
      organization
    ),
  };
}

type GroupedTags = {
  [key: string]: string | null;
};
function flattenTags(
  group: GroupedById,
  tagGroups: GQL.GetTagGroupsByEnrollmentPeriod_tag_group[]
) {
  const formLevelTags = group.form.tags.map(
    (tag) => tag.enrollment_period_tag.name
  );

  const groupTagsByTagGroupName = tagGroups
    .map<GroupedTags>((tagGroup) => ({
      [`${tagGroup.name} tags`]:
        group.formSchoolRank?.tags
          .filter(
            (tag) => tag.enrollment_period_tag?.tag_group_id === tagGroup.id
          )
          .map((tag) => tag.enrollment_period_tag.name)
          .join(", ") ?? null,
    }))
    .reduce((acc, curr) => ({ ...acc, ...curr }), {});

  return {
    "Form tags": formLevelTags.join(", ") ?? null,
    ...groupTagsByTagGroupName,
  };
}

type SubStatusRow = {
  "App Sub-status": string | null;
  "App Sub-status Date": string | null;
  "Program ID": string | null;
  "Program Label": string | null;
};
function flattenFormSubStatus(
  group: GroupedById,
  organization: RemoteData<unknown, GQL.Organization>
): SubStatusRow {
  if (group.offer) {
    return {
      "App Sub-status": group.offer.status,
      "App Sub-status Date": formatIsoDateToIso8601(
        group.offer.status_updated_at,
        organization
      ),
      "Program ID": group.offer.grade?.program?.id ?? null,
      "Program Label": group.offer.grade?.program?.label ?? null,
    };
  }
  if (group.waitlist) {
    return {
      "App Sub-status": group.waitlist.status,
      "App Sub-status Date": formatIsoDateToIso8601(
        group.waitlist.status_updated_at,
        organization
      ),
      "Program ID": null,
      "Program Label": null,
    };
  }

  if (
    group.form.status === GQL.form_status_enum.Admissions &&
    group.formSchoolRank?.status ===
      GQL.form_school_rank_status_enum.NotConsidered
  ) {
    return {
      "App Sub-status": GQL.form_school_rank_status_enum.NotConsidered,
      "App Sub-status Date": null,
      "Program ID": null,
      "Program Label": null,
    };
  }
  return {
    "App Sub-status": null,
    "App Sub-status Date": null,
    "Program ID": null,
    "Program Label": null,
  };
}

type WaitlistRow = {
  "Waitlist position": number | null;
};
function flattenWaitlist(group: GroupedById): WaitlistRow {
  return {
    "Waitlist position": group.waitlist?.waitlist_position?.position ?? null,
  };
}

type TiebreakerRow = {
  Tiebreaker: number | null;
};
function flattenTiebreaker(group: GroupedById): TiebreakerRow {
  return {
    Tiebreaker: group.formSchoolRank?.lottery_order ?? null,
  };
}

type FormDetailRow = {
  "App ID"?: string;
  "App Status": string | null;
  "App Status Date": string | null;
  "App Submitted Date": string | null;
};
function flattenFormDetail(
  group: GroupedById,
  organization: RemoteData<unknown, GQL.Organization>
): FormDetailRow {
  if (!group.form) {
    return {
      "App Status": null,
      "App Status Date": null,
      "App Submitted Date": null,
    };
  }
  return {
    "App ID": group.formId,
    "App Status": group.form.status,
    "App Status Date": formatIsoDateToIso8601(
      group.form.status_updated_at,
      organization
    ),
    "App Submitted Date": formatIsoDateToIso8601(
      group.form.submitted_at,
      organization
    ),
  };
}

type AttendingSchoolRow = {
  "Attendance status": string | null;
  "Attending school name": string | null;
  "Attending school ID": string | null;
};

function flattenAttendingSchools(group: GroupedById): AttendingSchoolRow {
  const attending_school = group.form?.form_attending_school;
  return {
    "Attendance status": attending_school?.attendance_status || "",
    "Attending school name": attending_school?.school?.name || "",
    "Attending school ID": attending_school?.school_id || "",
  };
}

function formatVerifications(
  group: GroupedById,
  formVerifications: GqlVerification[]
) {
  return Object.fromEntries(
    formVerifications.map((v) => [
      `App Verification "${v.label}"`,
      group.form.form_verification_results.find(
        (vr) => vr.form_verification_id === v.id
      )?.verification_status ?? GQL.verification_status_enum.Pending,
    ]) ?? []
  );
}

type AnswerGroup = {
  questionId: uuid | null;
  externalId?: string | null;
  formAnswer?: GQL.ExportForms_form_form_answers;
  gradeAnswer?: GQL.ExportForms_form_grades_answers;
  formAddress?: GQL.ExportForms_form_form_addresses;
};

type GroupedById = {
  formId: uuid;
  form: GQL.ExportForms_form;
  formSchoolRank: null | GQL.ExportForms_form_form_school_ranks;
  offer: null | GQL.ExportForms_form_form_school_ranks_offers;
  waitlist: null | GQL.ExportForms_form_form_school_ranks_waitlists;
  applicant: null | GQL.ExportForms_form_person;
  guardian: null | GQL.ExportForms_form_applicant_guardians_guardian;
  answers: Record<uuid, AnswerGroup>;
  disclaimers: GQL.ExportForms_form_form_disclaimers[];
};

export function groupFormDetailsById(data: GQL.ExportForms): GroupedById[] {
  return data.form.flatMap((form) => {
    const formSchoolRanks = form.form_school_ranks.length
      ? form.form_school_ranks
      : [null];
    return formSchoolRanks.map<GroupedById>((formSchoolRank) => ({
      formId: form.id,
      form,
      formSchoolRank,
      offer: formSchoolRank?.offers[0] ?? null,
      waitlist: formSchoolRank?.waitlists[0] ?? null,
      applicant: form.person,
      guardian: form.applicant_guardians[0]?.guardian ?? null,
      answers: collateAnswers(form),
      disclaimers: form.form_disclaimers,
    }));
  });
}

function collateAnswers(form: GQL.ExportForms_form) {
  const answers: Record<uuid, AnswerGroup> = {};
  function getAnswer(questionId: uuid) {
    return answers[questionId] ?? (answers[questionId] = { questionId });
  }
  for (const formAnswer of form.form_answers) {
    getAnswer(formAnswer.question_id).formAnswer = formAnswer;
  }
  for (const gradeAnswer of form.grades_answers) {
    getAnswer(gradeAnswer.question_id).gradeAnswer = gradeAnswer;
  }
  for (const formAddress of form.form_addresses) {
    getAnswer(formAddress.question_id).formAddress = formAddress;
  }
  for (const bankedAnswer of form.custom_question_answer_bank_relationships) {
    getAnswer(bankedAnswer.custom_question_id).externalId =
      bankedAnswer.person_answer_bank.person_answer_bank_external_relationship?.external_id;
  }
  return answers;
}

const formatLanguage = (code: string | null | undefined): string => {
  if (!code) return "";
  return new Intl.DisplayNames(["en"], { type: "language" }).of(code) || "";
};

const flattenDisclaimer = (
  group: GroupedById,
  disclaimerSectionTitle: string | null
) => {
  // Assumption: only one disclaimer section per form
  return {
    [disclaimerSectionTitle ?? "Disclaimer Signature"]:
      group.disclaimers[0]?.signature,
  };
};
