import { useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { useLazyRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { FormikForm, OrganizationForm } from "./Form";
import { CREATE_ORGANIZATION } from "./graphql/mutations";
import { GET_ORGANIZATIONS } from "./graphql/queries";
import { useOrgService } from "./hooks/useOrgService";
import * as RD from "src/types/remoteData";

export const New = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [createOrganization] = useRemoteDataMutation<
    GQL.CreateOrganization,
    GQL.CreateOrganizationVariables
  >(CREATE_ORGANIZATION);
  const [getOrganizations] = useLazyRemoteDataQuery<GQL.GetOrganizations>(
    GET_ORGANIZATIONS,
    {
      fetchPolicy: "network-only",
    }
  );

  const { updateOrgConfigs } = useOrgService({ orgPath: RD.notAsked() });

  const handleSubmit = React.useCallback(
    async (values: FormikForm) => {
      try {
        setLoading(true);
        await createOrganization({
          variables: {
            organization: {
              name: values.name,
              path: values.path,
              timezone_name: values.timezoneName,
            },
          },
        });
        await updateOrgConfigs(values.path, values.organizationConfigs);
        await getOrganizations();
        navigate(Url.Admin.Organizations.index());
      } catch (error) {
        setLoading(false);
        console.error(error);
        const id = "NewOrganization";
        if (!toast.isActive(id))
          toast({
            id,
            title: "Error creating organization",
            description:
              "Please try again later or report the problem to our support team.",
            isClosable: true,
            status: "error",
          });
      }
    },
    [createOrganization, getOrganizations, navigate, toast, updateOrgConfigs]
  );

  return <OrganizationForm onSubmit={handleSubmit} submitting={loading} />;
};
